import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { InvoiceReceipt } from '../../models';

export const featureAdapter: EntityAdapter<InvoiceReceipt> =
  createEntityAdapter<InvoiceReceipt>({
    selectId: (model) => model.id,
  });

export interface State extends EntityState<InvoiceReceipt> {
  pagination: IPagination;
  isLoading?: boolean;
  isUploadXmlLoading: boolean;
  error?: any;
  invoiceDetail: string;
  exportFileId: number;
}

export const initialState: State = featureAdapter.getInitialState({
  pagination: null,
  isLoading: false,
  isUploadXmlLoading: false,
  error: null,
  invoiceDetail: null,
  exportFileId: null,
});
