import { createAction, props } from '@ngrx/store';

export const loadRequest = createAction(
  '[View Sdi Format] Load Request',
  props<{ id: number }>(),
);

export const loadSuccess = createAction(
  '[View Sdi Format] Load Success',
  props<{ xml: string }>(),
);

export const loadFailure = createAction(
  '[View Sdi Format] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[View Sdi Format] Reset State');
