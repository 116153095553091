import { createFeatureSelector, createSelector } from '@ngrx/store';

import { InvoiceReceipt } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getExportFileId = (state: State) => state.exportFileId;

export const getIsLoading = (state: State) => state.isLoading;

export const getIsUploadXmlLoading = (state: State) => state.isUploadXmlLoading;

export const getPagination = (state: State) => state.pagination;

export const getInvoiceDetailReciptData = (state: State) => state.invoiceDetail;

export const selectInvoicesReceiptsState =
  createFeatureSelector<State>('invoicesReceipts');

export const selectAllInvoicesReceiptsItems = featureAdapter.getSelectors(
  selectInvoicesReceiptsState,
).selectAll;

export const selectInvoiceReceiptById = (id: string) =>
  createSelector(
    selectAllInvoicesReceiptsItems,
    (invoicesReceipts: InvoiceReceipt[]) =>
      invoicesReceipts && invoicesReceipts.find((p) => p.id === +id),
  );

export const selectInvoicesReceiptsError = createSelector(
  selectInvoicesReceiptsState,
  getError,
);

export const selectExportFileId = createSelector(
  selectInvoicesReceiptsState,
  getExportFileId,
);

export const selectInvoiceDetailReceipt = createSelector(
  selectInvoicesReceiptsState,
  getInvoiceDetailReciptData,
);

export const selectInvoicesReceiptsIsLoading = createSelector(
  selectInvoicesReceiptsState,
  getIsLoading,
);

export const selectInvoicesReceiptsIsUploadXmlLoading = createSelector(
  selectInvoicesReceiptsState,
  getIsUploadXmlLoading,
);

export const selectInvoicesReceiptsPagination = createSelector(
  selectInvoicesReceiptsState,
  getPagination,
);
