import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { createAction, props } from '@ngrx/store';
import { NzUploadFile } from 'ng-zorro-antd/upload';

import { InvoiceReceipt, InvoiceReceiptExportFileRequest } from '../../models';

export const UploadInvoicesReceiptsXmlRequest = createAction(
  '[Invoices Receipts] Upload Xml Files Request',
  props<{
    files: NzUploadFile[];
    onTakeAction?: () => any;
    loadInvoicesReceiptsRequestPayload?: { vat_code: string; params: any };
  }>(),
);

export const UploadInvoicesReceiptsXmlSuccess = createAction(
  '[Invoices Receipts] Upload Xml Files Success',
);

export const UploadInvoicesReceiptsXmlFailure = createAction(
  '[Invoices Receipts] Upload Xml Files Failure',
  props<{ error: any }>(),
);

export const loadInvoicesReceiptsRequest = createAction(
  '[Invoices Receipts] Load Request',
  props<{ vat_code: string; params: any }>(),
);

export const loadInvoicesReceiptsSuccess = createAction(
  '[Invoices Receipts] Load Success',
  props<{ invoicesReceipts: InvoiceReceipt[]; pagination: IPagination }>(),
);

export const loadInvoicesReceiptsFailure = createAction(
  '[Invoices Receipts] Load Failure',
  props<{ error: any }>(),
);

export const loadInvoiceDetailReceiptsRequest = createAction(
  '[Invoices Receipts] Load Detail Request',
  props<{ invoice_id: number }>(),
);

export const loadInvoiceDetailReceiptsSuccess = createAction(
  '[Invoices Receipts] Load Detail Success',
  props<{ invoiceDetail: string }>(),
);

export const loadInvoiceDetailReceiptsFailure = createAction(
  '[Invoices Receipts] Load Detail Failure',
  props<{ error: any }>(),
);

export const resetInvoiceDetailReceiptsState = createAction(
  '[Invoices Receipts] Reset Detail State',
);

export const resetInvoicesReceiptsState = createAction(
  '[Invoices Receipts] Reset State',
);

export const exportFileFailure = createAction(
  '[Invoices Receipts] Export Failure',
  props<{ error: any }>(),
);

export const exportFileRequest = createAction(
  '[Invoices Receipts] Export Failure',
  props<{ params: InvoiceReceiptExportFileRequest }>(),
);

export const exportFileSuccess = createAction(
  '[Invoices Receipts] Export Success',
  props<{ exportFileId: number }>(),
);
