import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { NzModalService } from 'ng-zorro-antd/modal';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { UploadFilesResultsComponent } from '../../features/commons/invoices-receipts/upload-files-results/upload-files-results.component';

import { InvoicesReceiptsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class InvoicesReceiptsStoreEffects {
  constructor(
    private dataService: InvoicesReceiptsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private translate: TranslateService,
    private notifications: NotificationService,
    private modalService: NzModalService,
  ) {}

  uploadInvoiceReceiptXmlFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.UploadInvoicesReceiptsXmlRequest),
      mergeMap(({ files, loadInvoicesReceiptsRequestPayload, onTakeAction }) =>
        this.dataService.uploadInvoiceReceiptXmlFiles(files).pipe(
          switchMap((response) => {
            const { data } = response;
            const { import_error, import_skip, import_success } = data[0];

            if (import_error.total || import_skip.total) {
              const modal = this.modalService.create<
                UploadFilesResultsComponent,
                Partial<UploadFilesResultsComponent>
              >({
                nzTitle: null,
                nzClosable: false,
                nzContent: UploadFilesResultsComponent,
                nzData: { results: response.data[0] },
                nzFooter: [
                  {
                    label: upperFirst(
                      this.translate.instant('import_invoices'),
                    ),
                    type: 'primary',
                    onClick: () => {
                      onTakeAction();
                      modal.close();
                    },
                  },
                  {
                    label: upperFirst(this.translate.instant('close')),
                    type: 'default',
                    onClick: () => modal.close(),
                  },
                ],
              });
            } else {
              this.notifications.push({
                title: upperFirst(this.translate.instant('done')),
                content:
                  `${import_success.total} ` +
                  upperFirst(
                    this.translate.instant('file_uploaded_successfully', {
                      count: import_success.total,
                    }),
                  ),
                type: 'success',
              });
            }
            let actions: any[] = [
              fromActions.UploadInvoicesReceiptsXmlSuccess(),
            ];
            if (loadInvoicesReceiptsRequestPayload) {
              actions = [
                ...actions,
                fromActions.loadInvoicesReceiptsRequest(
                  loadInvoicesReceiptsRequestPayload,
                ),
              ];
            }
            return actions;
          }),
          catchError((error) => {
            this.notifications.push({
              title: upperFirst(this.translate.instant('error')),
              content: this.translate.instant('import_files_failure'),
              type: 'error',
            });
            return of(fromActions.UploadInvoicesReceiptsXmlFailure(error));
          }),
        ),
      ),
    ),
  );

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadInvoicesReceiptsRequest),
      switchMap(({ vat_code, params }) =>
        this.dataService.load(vat_code, params).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadInvoicesReceiptsSuccess({
              invoicesReceipts: response.data,
              pagination: response.meta.pagination,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadInvoicesReceiptsFailure(error));
          }),
        ),
      ),
    ),
  );

  loadDetail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadInvoiceDetailReceiptsRequest),
      switchMap((action) =>
        this.dataService.loadDetail(action.invoice_id).pipe(
          map((response) =>
            fromActions.loadInvoiceDetailReceiptsSuccess({
              invoiceDetail: response,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadInvoiceDetailReceiptsFailure(error));
          }),
        ),
      ),
    ),
  );

  export$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.exportFileRequest),
      switchMap(({ params }) =>
        this.dataService.exportFile(params).pipe(
          map(({ data }) =>
            fromActions.exportFileSuccess({
              exportFileId: data.export_ids[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.exportFileFailure(error));
          }),
        ),
      ),
    ),
  );
}
