import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const featureAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<any> {
  isLoading?: boolean;
  error?: any;
  xml: string;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
  xml: null,
});
