<ng-template #title>
  <div class="by-flex by-flex-between">
    <span class="bold">{{ 'imported_invoices' | translate | upperfirst }}</span>
    <span class="bold"> {{ importSummary }}</span>
  </div>
</ng-template>
<by-page-header
  [titleTemplate]="title"
  [byStyle]="{ 'padding-top': 0 }"
></by-page-header>
<div class="by-flex by-flex-direction--column" class="files">
  <ng-container *ngFor="let resultStatus of results | keys">
    <div
      *ngIf="results[resultStatus].total"
      class="by-flex by-flex-direction--column"
    >
      <ng-container
        *ngFor="let file of results[resultStatus].files; let last = last"
      >
        <div
          class="by-flex by-flex-between by-flex-base"
          style="min-height: 24px"
        >
          <span [ngClass]="{ 'by-mb-5': !last }">{{ file }}</span>
          <div class="ellipsis-box">
            <div class="ellipsis-element"></div>
          </div>
          <i
            nz-tooltip
            [nzTooltipTitle]="results[resultStatus].message | upperfirst"
            [ngClass]="statusIcon[resultStatus]"
            class="by-mr-10"
          ></i>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
