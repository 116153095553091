import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { UploadInvoiceReceiptXmlResponse } from '../../../../models';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

type StatusImport = {
  [UploadInvoiceReceiptXmlStatus in keyof UploadInvoiceReceiptXmlResponse]: string;
};

@Component({
  selector: 'by-upload-files-results',
  templateUrl: './upload-files-results.component.html',
  styleUrls: ['./upload-files-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFilesResultsComponent implements OnInit {
  readonly nzDataModal: Partial<UploadFilesResultsComponent> =
    inject(NZ_MODAL_DATA);

  @Input() results: UploadInvoiceReceiptXmlResponse = this.nzDataModal.results;

  constructor() {}

  statusIcon: StatusImport = {
    import_success: 'fas fa-check-circle color--green',
    import_skip: 'fas fa-exclamation-triangle color--orange',
    import_error: 'fas fa-times-circle color--red',
  };

  ngOnInit(): void {}

  get totalFiles(): number {
    const { import_error, import_skip, import_success } = this.results;
    return (
      (import_error?.total || 0) +
      (import_skip?.total || 0) +
      (import_success?.total || 0)
    );
  }

  get importSummary(): string {
    return `${this.results.import_success?.total || 0}/${this.totalFiles}`;
  }
}
